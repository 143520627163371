import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {        
        if (document.querySelector('.c-marketing__graph')) {
            gsap.from('.c-marketing__graph', {
                scrollTrigger: {
                    trigger: '.c-marketing__graph',
                    start: 'top 90%',
                    end: 'center 25%',
                    toggleActions: "play complete complete reverse",
                    scrub: 1,
                },
                opacity: 0
            });
        }
    };

}, false)
